import { DCardView, dcardLoader } from "views/products/dcard/DCardView";
import { DCards } from "views/products/dcard/DCards";
import CreaDCard from "views/products/dcard/NewDCard";
import CreaDCardOwnership from "views/products/dcard/ownership/NewOwnership";
import { RouteObject } from "react-router-dom";

export const DCardRoute: RouteObject = {
  path: "dcard",
  children: [
    {
      index: true,
      Component: DCards,
    },
    {
      path: "new",
      Component: CreaDCard,
    },
    {
      path: ":dcardUuid",
      loader: dcardLoader,
      Component: DCardView,
    },
    {
      path: "ownership/new",
      Component: CreaDCardOwnership,
    },
  ],
};
