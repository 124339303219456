import * as React from 'react';
import AspectRatio, { AspectRatioProps } from '@mui/joy/AspectRatio';

export default function DigitalMotusLogo({ sx, ...props }: AspectRatioProps) {
  return (
    <AspectRatio
      ratio="1"
      variant="plain"
      {...props}
      sx={[
        {
          width: 36,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <svg width="300px" height="300px" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <title>logo-DM</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="logo-DM" transform="translate(6.000000, 6.000000)">
                  <path d="M0.17827438,25.21728 C0.17827438,11.5769468 11.2584793,0.519587629 24.926995,0.519587629 L263.06402,0.519587629 C276.732536,0.519587629 287.81274,11.5769468 287.81274,25.21728 L287.81274,262.863301 C287.81274,276.503574 276.732536,287.560874 263.06402,287.560874 L24.926995,287.560874 C11.2584793,287.560874 0.17827438,276.503574 0.17827438,262.863301 L0.17827438,25.21728 Z" id="Fill-1" fill="#0B4A8E"></path>
                  <path d="M0.17827438,25.21728 C0.17827438,11.5769468 11.2584793,0.519587629 24.926995,0.519587629 L263.06402,0.519587629 C276.732536,0.519587629 287.81274,11.5769468 287.81274,25.21728 L287.81274,262.863301 C287.81274,276.503574 276.732536,287.560874 263.06402,287.560874 L24.926995,287.560874 C11.2584793,287.560874 0.17827438,276.503574 0.17827438,262.863301 L0.17827438,25.21728 Z" id="Stroke-2" stroke="#0B4A8E" strokeWidth="11.091"></path>
                  <polygon id="Fill-3" fill="#009B5F" points="14.4584926 247.425729 100.814817 247.42561 100.817197 242.626877 14.4603967 242.626936"></polygon>
                  <polygon id="Fill-4" fill="#FFFFFE" points="100.815888 247.42561 187.172569 247.425491 187.174592 242.626699 100.817911 242.626817"></polygon>
                  <polygon id="Fill-5" fill="#D84748" points="187.173283 247.425491 273.529964 247.425432 273.532463 242.62652 187.175484 242.626699"></polygon>
                  <path d="M61.7543405,140.54691 C61.7543405,140.54691 92.3730248,141.087696 100.230902,140.006064 C108.08878,138.92449 146.29442,125.404345 157.403901,84.5734862 C157.403901,84.5734862 174.745428,153.255845 104.566255,166.77599 L61.4833587,166.77599 L61.7543405,140.54691 Z M60.9414545,137.031647 L60.9414545,39.4160363 C60.9414545,39.4160363 127.056139,32.6560528 143.313858,59.6963431 C143.313858,59.6963431 155.507088,33.1967802 226.499028,39.6865781 L226.499028,167.5872 L194.525613,167.5872 L194.796536,70.5124355 C194.796536,70.5124355 175.558314,59.6963431 160.384463,75.920541 C145.210552,92.1447984 142.229931,109.991416 128.410929,114.858616 C128.410929,114.858616 135.726902,66.456386 93.7278149,67.538019 L92.6439471,136.761224 L60.9414545,137.031647 Z" id="Fill-6" fill="#FFFFFE"></path>
                  <path d="M36.6741223,209.423624 C36.6741223,205.470482 36.9700959,201.850471 34.0085752,198.895116 C32.2688529,197.158981 29.7886017,196.382746 27.0490909,196.382746 L17.5353322,196.382746 L17.5353322,222.68546 L27.0490909,222.68546 C29.7886017,222.68546 32.2688529,221.909819 34.0085752,220.173684 C36.9700959,217.21827 36.6741223,213.376112 36.6741223,209.423624 Z M31.528324,209.423624 C31.528324,213.41281 31.3803372,215.297221 30.4177983,216.479268 C29.5293421,217.550212 28.3448529,218.104894 26.567762,218.104894 L22.6810711,218.104894 L22.6810711,200.963787 L26.567762,200.963787 C28.3448529,200.963787 29.5293421,201.517875 30.4177983,202.588879 C31.3803372,203.77152 31.528324,205.433844 31.528324,209.423624 Z" id="Fill-7" fill="#FFFFFE"></path>
                  <polygon id="Fill-8" fill="#FFFFFE" points="47.0758017 222.68546 47.0758017 196.382746 41.9300628 196.382746 41.9300628 222.68546"></polygon>
                  <path d="M71.9885752,211.898227 L71.9885752,208.056663 L62.0307967,208.056663 L62.0307967,212.341806 L66.8800264,212.341806 L66.8800264,213.338939 C66.8800264,214.779771 66.4727207,215.961818 65.6955372,216.848501 C64.7702479,217.846228 63.5485091,218.326446 62.0307967,218.326446 C60.6242975,218.326446 59.5138314,217.882807 58.7360529,216.959545 C57.6994909,215.740325 57.4775405,214.7059 57.4775405,209.534133 C57.4775405,204.325608 57.773514,203.365113 58.7360529,202.145834 C59.5505455,201.222572 60.6610711,200.742294 62.0307967,200.742294 C64.5850711,200.742294 66.1400331,202.034791 66.7692893,204.583799 L71.9518017,204.583799 C71.2486413,200.003292 68.0278017,196.161254 62.0307967,196.161254 C59.2172628,196.161254 56.8482843,197.121748 54.9232661,199.042738 C53.776086,200.188206 53.0722711,201.480703 52.776238,202.995345 C52.480324,204.473349 52.3322777,206.652529 52.3322777,209.534133 C52.3322777,212.415677 52.480324,214.595451 52.776238,216.072861 C53.0722711,217.550212 53.776086,218.880594 54.9232661,220.025409 C56.8482843,221.946458 59.2172628,222.906953 62.0307967,222.906953 C64.9923174,222.906953 67.4352595,221.98369 69.4715504,219.9149 C71.2852959,217.993851 71.9885752,215.740325 71.9885752,211.898227 Z" id="Fill-9" fill="#FFFFFE"></path>
                  <polygon id="Fill-10" fill="#FFFFFE" points="81.9462942 222.68546 81.9462942 196.382746 76.8004959 196.382746 76.8004959 222.68546"></polygon>
                  <polygon id="Fill-11" fill="#FFFFFE" points="105.045263 200.963787 105.045263 196.382746 86.1290182 196.382746 86.1290182 200.963787 93.0145388 200.963787 93.0145388 222.68546 98.1596826 222.68546 98.1596826 200.963787"></polygon>
                  <path d="M129.58804,222.68546 L119.963484,196.382746 L115.928807,196.382746 L106.341025,222.68546 L111.708238,222.68546 L113.299974,218.030548 L122.665745,218.030548 L124.220826,222.68546 L129.58804,222.68546 Z M121.29602,213.708708 L114.743722,213.708708 L118.075775,204.140754 L121.29602,213.708708 Z" id="Fill-12" fill="#FFFFFE"></path>
                  <polygon id="Fill-13" fill="#FFFFFE" points="149.947498 222.68546 149.947498 218.104894 137.990737 218.104894 137.990737 196.382746 132.844879 196.382746 132.844879 222.68546"></polygon>
                  <polygon id="Fill-14" fill="#FFFFFE" points="178.006969 222.68546 178.006969 196.382746 172.935729 196.382746 166.087458 210.605671 159.201937 196.382746 154.130102 196.382746 154.130102 222.68546 159.27596 222.68546 159.27596 207.354953 164.34714 217.328719 167.82718 217.328719 172.861706 207.354953 172.861706 222.68546"></polygon>
                  <path d="M202.734889,209.534133 C202.734889,204.990265 202.771636,201.702789 200.106744,199.042738 C198.25563,197.19556 195.997388,196.161254 192.999213,196.161254 C190.000324,196.161254 187.705428,197.19556 185.854374,199.042738 C183.189481,201.702789 183.263385,204.990265 183.263385,209.534133 C183.263385,214.077941 183.189481,217.365951 185.854374,220.025409 C187.705428,221.872647 190.000324,222.906953 192.999213,222.906953 C195.997388,222.906953 198.25563,221.872647 200.106744,220.025409 C202.771636,217.365951 202.734889,214.077941 202.734889,209.534133 Z M197.589124,209.534133 C197.589124,214.595451 197.29321,215.814077 196.293421,216.922372 C195.553428,217.772358 194.405593,218.326446 192.999213,218.326446 C191.592119,218.326446 190.407689,217.772358 189.667041,216.922372 C188.667848,215.814077 188.408707,214.595451 188.408707,209.534133 C188.408707,204.473349 188.667848,203.25407 189.667041,202.145834 C190.407689,201.296383 191.592119,200.742294 192.999213,200.742294 C194.405593,200.742294 195.553428,201.296383 196.293421,202.145834 C197.29321,203.25407 197.589124,204.473349 197.589124,209.534133 Z" id="Fill-15" fill="#FFFFFE"></path>
                  <polygon id="Fill-16" fill="#FFFFFE" points="224.945375 200.963787 224.945375 196.382746 206.029131 196.382746 206.029131 200.963787 212.914651 200.963787 212.914651 222.68546 218.059974 222.68546 218.059974 200.963787"></polygon>
                  <path d="M248.118426,213.671535 L248.118426,196.382746 L243.009878,196.382746 L243.009878,213.487215 C243.009878,216.515906 241.196132,218.326446 238.419907,218.326446 C235.643623,218.326446 233.866592,216.515906 233.866592,213.487215 L233.866592,196.382746 L228.721448,196.382746 L228.721448,213.671535 C228.721448,219.249768 233.015445,222.906953 238.419907,222.906953 C243.82437,222.906953 248.118426,219.249768 248.118426,213.671535 Z" id="Fill-17" fill="#FFFFFE"></path>
                  <path d="M270.884707,214.927452 C270.884707,212.600531 270.255332,210.679482 268.848952,209.349753 C267.737891,208.314854 266.220655,207.650256 263.814486,207.317661 L260.704919,206.874616 C259.631226,206.7264 258.779901,206.320528 258.261441,205.840251 C257.706149,205.322801 257.484198,204.621031 257.484198,203.95584 C257.484198,202.145834 258.816674,200.631251 261.556245,200.631251 C263.295848,200.631251 265.29483,200.852744 266.960707,202.441257 L270.218142,199.227652 C267.960496,197.047937 265.332198,196.161254 261.704112,196.161254 C256.003736,196.161254 252.523696,199.449204 252.523696,204.140754 C252.523696,206.357166 253.152893,208.056663 254.41194,209.312581 C255.59637,210.45793 257.262248,211.159759 259.519894,211.492355 L262.703901,211.9354 C263.925223,212.120313 264.517646,212.378445 265.035689,212.858722 C265.590922,213.376112 265.850063,214.151812 265.850063,215.075668 C265.850063,217.181038 264.221732,218.326446 261.186188,218.326446 C258.742711,218.326446 256.484886,217.772358 254.818592,216.110093 L251.487134,219.434682 C254.078717,222.057501 257.114202,222.906953 261.112106,222.906953 C266.627425,222.906953 270.884707,220.025409 270.884707,214.927452 Z" id="Fill-18" fill="#FFFFFE"></path>
              </g>
          </g>
      </svg>
    </AspectRatio>
  );
}


