export function DCardHeaderRow() {
  return (
    <tr>
      <th
        style={{
          width: "120px",
          padding: "12px 16px",
        }}
      >
        UUID
      </th>
      <th style={{ width: 100, padding: "12px 6px" }}>Assegnata</th>
      <th style={{ width: 100, padding: "12px 6px" }}>Stato</th>
      <th style={{ width: 140, padding: "12px 6px" }}>Titolo</th>
      <th style={{ width: 140, padding: "12px 6px" }}>Link</th>
      <th aria-label="last" style={{ width: "250px" }} />
    </tr>
  );
}
