import { CompanyDto } from "services/openapi";

function predicate(company: CompanyDto) {
  return company.serviceStatus.dCardAvailable === true;
}

export function activatedDCardServiceFilter(
  companies: CompanyDto[]
): CompanyDto[] {
  return companies.filter(predicate);
}
