import { companyApi } from "services/api";
import { CompanyDto } from "services/openapi/api";
import { LoaderFunctionArgs } from "react-router-dom";

interface CompanyLoaderParams {
  companyId: number;
}

export async function companyLoader({
  params,
}: LoaderFunctionArgs): Promise<CompanyDto> {
  const data = params as unknown as CompanyLoaderParams;
  const companyId = data.companyId as number;
  const res = await companyApi.companyControllerFindOneByOrFail(companyId);
  return res.data;
}
