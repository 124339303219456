import * as React from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { cardApi } from "services/api";
import { DCard } from "services/openapi";
import { DCardHeaderRow } from "./DCardHeaderRow";
import { DCardTableContent } from "./DCardTableContent";
import { SkeletonTableContent } from "../SkeletonTable";

export function DCardTable() {
  const [loaded, setLoaded] = React.useState(false);
  const [dCards, setDCards] = React.useState<DCard[]>([]);

  async function fetchAllDCards() {
    return await cardApi
      .dCardApiControllerFetchAll()
      .then((res) => setDCards(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoaded(true));
  }

  React.useEffect(() => {
    fetchAllDCards();
  }, []);

  return (
    <Sheet
      variant="outlined"
      sx={{
        "--TableCell-height": "40px",
        // the number is the amount of the header rows.
        "--TableHeader-height": "calc(1 * var(--TableCell-height))",
        // background needs to have transparency to show the scrolling shadows
        "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
        "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
        overflow: "auto",
        background: (
          theme
        ) => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
          linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
          radial-gradient(
            farthest-side at 0 50%,
            rgba(0, 0, 0, 0.12),
            rgba(0, 0, 0, 0)
          ),
          radial-gradient(
              farthest-side at 100% 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            )
            0 100%`,
        backgroundSize:
          "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "local, local, scroll, scroll",
        backgroundPosition:
          "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
        backgroundColor: "background.surface",
      }}
    >
      <Table
        aria-labelledby="tableTitle"
        hoverRow
        sx={{
          "& tr > *:first-of-type": {
            position: "sticky",
            left: 0,
            boxShadow: "1px 0 var(--TableCell-borderColor)",
            bgcolor: "background.surface",
          },
          "& tr > *:last-child": {
            position: "sticky",
            right: 0,
            bgcolor: "var(--TableCell-headBackground)",
          },
        }}
      >
        <thead>
          <DCardHeaderRow />
        </thead>
        <tbody>
          {loaded
            ? <DCardTableContent dcards={dCards} onDCardDelete={fetchAllDCards} />
            : <SkeletonTableContent nRows={6} nCellPerRow={6} />
          }
        </tbody>
      </Table>
    </Sheet>
  );
}