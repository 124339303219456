import * as React from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { companyApi } from "services/api";
import { CompanyDto } from "services/openapi";
import { SkeletonTableContent } from "../SkeletonTable";
import { CompanyHeaderRow } from "./CompanyHeaderRow";
import { CompanyTableContent } from "./CompanyTableContent";

export function CompanyTable() {
  const [loading, setLoading] = React.useState(true);
  const [companies, setCompanies] = React.useState<CompanyDto[]>([]);

  function fetchAllCompanies() {
    companyApi
      .companyControllerFindAll()
      .then((res) => setCompanies(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  React.useEffect(() => {
    fetchAllCompanies();
  }, []);

  return (
    <Sheet
      className="OrderTableContainer"
      variant="outlined"
      sx={{
        display: { xs: "none", sm: "initial" },
        width: "100%",
        borderRadius: "sm",
        flexShrink: 1,
        overflow: "auto",
        minHeight: 0,
      }}
    >
      <Table
        aria-labelledby="tableTitle"
        hoverRow
        sx={{
          "--TableCell-headBackground": "var(--joy-palette-background-level1)",
          "--Table-headerUnderlineThickness": "1px",
          "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
          "--TableCell-paddingY": "4px",
          "--TableCell-paddingX": "8px",
          "& tr > *:first-of-type": {
            position: "sticky",
            left: 0,
            boxShadow: "1px 0 var(--TableCell-borderColor)",
            bgcolor: "background.surface",
          },
          "& tr > *:last-child": {
            position: "sticky",
            right: 0,
          },
        }}
      >
        <thead>
          <CompanyHeaderRow />
        </thead>
        <tbody>
          {loading ? (
            <SkeletonTableContent nRows={16} nCellPerRow={6} />
          ) : (
            <CompanyTableContent
              companies={companies}
              onCompanyDelete={fetchAllCompanies}
            />
          )}
        </tbody>
      </Table>
    </Sheet>
  );
}
