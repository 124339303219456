import Button from "@mui/joy/Button/Button";
import { cardApi } from "services/api";

interface DCardDeactivateButtonProps {
    cardUuid: string;
    onDeactivate?: Function;
}

export function DCardDeactivateButton({ cardUuid, onDeactivate }: DCardDeactivateButtonProps) {
    async function handleOnClick() {
        await cardApi.dCardApiControllerDeactivate(cardUuid);
        if (typeof onDeactivate !== "undefined") await onDeactivate();
    }

    return (
        <Button
            size="sm"
            variant="solid"
            color="danger"
            onClick={handleOnClick}
        >
            Disattiva
        </Button>
    );
}