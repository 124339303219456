import { SkeletonTableCell } from "./SkeletonTableCell";

interface SkeletonTableRowProps {
  nCell: number;
}

export function SkeletonTableRow({ nCell }: SkeletonTableRowProps) {
  const cells = [];

  for (let i = 0; i < nCell; i++) {
    const seed = "skeleton-table-row-";
    const key = seed + Math.random().toString(36).slice(2, 7);
    cells.push(<SkeletonTableCell key={key} />);
  }

  return <tr>{cells}</tr>;
}
