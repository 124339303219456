import React from "react";
import { SkeletonTableRow } from "./SkeletonTableRow";

interface SkeletonTableContentProps {
  nRows: number;
  nCellPerRow: number;
}

export function SkeletonTableContent({
  nRows,
  nCellPerRow,
}: SkeletonTableContentProps) {
  const rows = [];
  const randomKeySeed = Math.random().toString(36).slice(2, 7);

  for (let i = 0; i < nRows; i++)
    rows.push(
      <SkeletonTableRow
        key={"skeleton-table-row" + randomKeySeed + i}
        nCell={nCellPerRow}
      />
    );

  return <React.Fragment>{rows}</React.Fragment>;
}
