import Button from "@mui/joy/Button/Button";
import { cardApi } from "services/api";

interface DCardActivateButtonProps {
    cardUuid: string;
    onActivate?: Function;
}

export function DCardActivateButton({ cardUuid, onActivate }: DCardActivateButtonProps) {
    async function handleOnClick() {
        await cardApi.dCardApiControllerActivate(cardUuid);
        if (typeof onActivate !== "undefined") await onActivate();
    }

    return (
        <Button
            size="sm"
            variant="solid"
            color="success"
            onClick={handleOnClick}
        >
            Attiva
        </Button>
    );
}