import * as React from "react";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Button from "@mui/joy/Button";
import { Card, Divider, FormControl, FormLabel, Select, Stack } from "@mui/joy";
import { useNavigate } from "react-router";
import { cardOwnershipApi, companyApi } from "services/api";
import { CompanyDto } from "services/openapi/api";
import useScript from "hooks/useScript";
import { Layout } from "components/Layout";
import Option from "@mui/joy/Option";

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export default function CreaDCardOwnership() {
  const status = useScript(`https://unpkg.com/feather-icons`);
  const [companyID, setCompanyID] = React.useState<number>(0);
  const [companies, setCompanies] = React.useState<CompanyDto[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [created, setCreated] = React.useState(false);
  const navigate = useNavigate();

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);

  React.useEffect(() => {
    companyApi
      .companyControllerFindAll()
      .then((r) => {
        const data: CompanyDto[] = r.data;
        const filtered = data.filter(
          (company) => company.serviceStatus.dCardAvailable === false
        );
        setCompanies(filtered);
      })
      .catch((e) => console.debug(e));
  }, []);

  return (
    <Layout>
      <form
        style={{ width: "-webkit-fill-available" }}
        onSubmit={(event) => {
          event.preventDefault();
          console.log(companyID);
          setIsSubmitting(true);
          cardOwnershipApi
            .dCardOwnershipApiControllerActivate(companyID)
            .then(() => {
              setCreated(true);
              navigate("/products/dcard");
            })
            .catch((_) => alert("Errore, inserisci tutti i dati"))
            .finally(() => setIsSubmitting(false));
        }}
      >
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: {
              xs: 2,
              md: 6,
            },
            pt: {
              xs: "calc(12px + var(--Header-height))",
              sm: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="/home"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Typography fontSize={12} fontWeight={500}>
                Prodotti
              </Typography>
              <Link
                underline="hover"
                color="neutral"
                href="/products/dcard"
                fontSize={12}
                fontWeight={500}
              >
                dCard
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Nuovo Abbonamento
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 1,
              gap: 1,
              alignItems: { xs: "start", sm: "center" },
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography level="h2">Crea Abbonamento</Typography>
          </Box>
          <Stack
            spacing={4}
            sx={{
              display: "flex",
              width: "-webkit-fill-available",
              maxWidth: "800px",
              mx: "auto",
              px: {
                xs: 2,
                md: 6,
              },
              py: {
                xs: 2,
                md: 3,
              },
            }}
          >
            <Card>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">Abbonamento</Typography>
                <Typography level="body-sm">
                  Informazioni sull'abbonamento
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ mb: 1, padding: "14px" }}>
                <Stack spacing={1} flexWrap="wrap" useFlexGap>
                  <FormControl>
                    <FormLabel>Azienda</FormLabel>
                    <Select
                      placeholder="Seleziona Azienda..."
                      onChange={(_, newValue) => {
                        const id = newValue as unknown as number;
                        setCompanyID(id);
                      }}
                      required
                    >
                      {companies.map((c) => (
                        <Option key={"a-" + c.id} value={c.id}>
                          {c.companyName}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
              <Divider />
              <Stack spacing={1} sx={{ padding: 3 }}>
                {!created ? (
                  <>
                    <Button
                      type="reset"
                      size="sm"
                      variant="soft"
                      color="neutral"
                      onClick={() => navigate(-1)}
                    >
                      Annulla
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      variant="solid"
                      loading={isSubmitting}
                    >
                      Crea
                    </Button>
                  </>
                ) : (
                  <Button color="success" size="sm" variant="solid">
                    Fatto!
                  </Button>
                )}
              </Stack>
            </Card>
          </Stack>
        </Box>
      </form>
    </Layout>
  );
}
