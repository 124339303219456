import { DCard } from "services/openapi";
import { DCardNoDataRow } from "./DCardNoDataRow";
import { DCardRow } from "./DCardRow";
import React from "react";

interface DCardTableContentProps {
  dcards: DCard[];
  onDCardDelete: Function;
}

export function DCardTableContent({
  dcards,
  onDCardDelete,
}: DCardTableContentProps) {
  return (
    <React.Fragment>
      {dcards.length === 0
        ? <DCardNoDataRow />
        : dcards.map((dcard) => (
          <DCardRow
            key={"dcard-data-" + dcard.uuid}
            dcard={dcard}
            onAction={onDCardDelete}
          />
        ))}
    </React.Fragment>
  )
}
