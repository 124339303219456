import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Layout } from "components/Layout";
import React from "react";
import { cardApi, companyApi } from "services/api";
import { CompanyCountCard, DCardPieCard, DCardServicePieCard } from "components/analytics";
import { CompanyDto, DCard } from "services/openapi";

interface HomeAnalitycs {
  companyCount: number;
  dcardCount: number;
  dCardServiceActiveCount: number;
  assignedDCardCount: number;
}

async function loadAnalitics(): Promise<HomeAnalitycs> {
  const dcardsReq = await cardApi.dCardApiControllerFetchAll();
  const companiesReq = await companyApi.companyControllerFindAll();
  const dcards = dcardsReq.data;
  const companies = companiesReq.data;
  const companyCount = companies.length;
  const dcardCount = dcards.length;

  let dCardServiceActiveCount = 0;
  let assignedDCardCount = 0;

  companies.forEach((company: CompanyDto) => {
    if (company.serviceStatus.dCardAvailable) dCardServiceActiveCount++;
  });

  dcards.forEach((dcard: DCard) => {
    if (dcard.ownership) assignedDCardCount++;
  });

  return {
    companyCount,
    dcardCount,
    dCardServiceActiveCount,
    assignedDCardCount,
  };
}

const HomeAnalyticsSample: HomeAnalitycs = {
  companyCount: 0,
  dcardCount: 0,
  dCardServiceActiveCount: 0,
  assignedDCardCount: 0,
};

export default function Home() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<HomeAnalitycs>(HomeAnalyticsSample);

  React.useLayoutEffect(() => {
    loadAnalitics()
      .then((data) => setData(data))
      .catch(() => alert("Errore nel caricamento dei dati"))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Layout>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: {
            xs: 2,
            md: 6,
          },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
          },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="/home"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Home
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            my: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography level="h2">Home</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "start",
            gap: "16px",
          }}
        >
          <CompanyCountCard loading={loading} count={data.companyCount} />
          <DCardPieCard
            loading={loading}
            assignedCount={data.assignedDCardCount}
            unassingned={data.dcardCount - data.assignedDCardCount}
          />
          <DCardServicePieCard
            loading={loading}
            withCount={data.dCardServiceActiveCount}
            withoutCount={data.companyCount - data.dCardServiceActiveCount}
          />
        </Box>
      </Box>
    </Layout>
  );
}
