import { loginAction } from "router/actions";
import { loginLoader } from "router/loaders";
import SignIn from "views/SignIn";
import { RouteObject } from "react-router-dom";

export const SignInRoute: RouteObject = {
  path: "sign-in",
  action: loginAction,
  loader: loginLoader,
  Component: SignIn,
};
