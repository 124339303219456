import * as React from "react";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import useScript from "hooks/useScript";
import { Layout } from "components/Layout";
import Button from "@mui/joy/Button";
import { EmailRounded, PhoneRounded } from "@mui/icons-material";
import { Alert, FormControl, FormLabel, Input, Stack } from "@mui/joy";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { companyApi } from "services/api";
import { CompanyDto, UpdateCompanyDto } from "services/openapi/api";
import { useLoaderData } from "react-router-dom";
import { DCardServiceInfo } from "components/sections";

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export default function ModificaAzienda() {
  const status = useScript(`https://unpkg.com/feather-icons`);
  const currentCompanyData = useLoaderData() as CompanyDto;
  const [formData, setFormData] =
    React.useState<UpdateCompanyDto>(currentCompanyData);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [created, setCreated] = React.useState(false);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);

  return (
    <Layout>
      <form
        style={{
          overflowY: "auto",
          width: "100%",
        }}
        onSubmit={(event) => {
          event.preventDefault();
          console.log(formData);
          setIsSubmitting(true);
          companyApi
            .companyControllerUpdate(formData)
            .then(() => setCreated(true))
            .catch((_) => alert("Errore, inserisci tutti i dati"))
            .finally(() => {
              setIsSubmitting(false);
              setTimeout(() => setCreated(false), 1000);
            });
        }}
      >
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: {
              xs: 2,
              md: 6,
            },
            pt: {
              xs: "calc(12px + var(--Header-height))",
              sm: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            width: "auto",
            overflow: "auto",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
          }}
        >
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="/home"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
            <Link
              underline="hover"
              color="neutral"
              href="/company"
              fontSize={12}
              fontWeight={500}
            >
              Aziende
            </Link>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              {formData.companyName} {formData.legalForm}
            </Typography>
          </Breadcrumbs>
          <Box
            sx={{
              display: "flex",
              my: 1,
              gap: 1,
              alignItems: { xs: "start", sm: "center" },
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography level="h2">{formData.companyName}</Typography>
            {created ? (
              <Button
                type="button"
                variant="solid"
                color="success"
                onClick={(event) => {
                  event.preventDefault();
                  setCreated(false);
                }}
              >
                Fatto
              </Button>
            ) : (
              <Button type="submit" variant="solid" loading={isSubmitting}>
                Salva Modifiche
              </Button>
            )}
          </Box>
          <Box sx={{ my: 1 }}>
            <Typography level="h3">Informazioni Aziendali</Typography>
            <Typography level="body-md">
              Informazioni identificative dell'azienda
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Stack
              sx={{ mb: 1 }}
              direction="row"
              spacing={1}
              flexWrap="wrap"
              useFlexGap
            >
              <FormControl>
                <FormLabel>Nome Azienda</FormLabel>
                <Input
                  defaultValue={formData.companyName}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      companyName: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="Pasticceria Nino d'Angelo"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Forma Legale</FormLabel>
                <Input
                  defaultValue={formData.legalForm}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      legalForm: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="srl"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Capitale</FormLabel>
                <Input
                  defaultValue={formData.capital}
                  onChange={(event) =>
                    setFormData({ ...formData, capital: event.target.value })
                  }
                  startDecorator="€"
                  size="sm"
                  placeholder="100.000"
                />
              </FormControl>
            </Stack>
            <Stack
              sx={{ mb: 1 }}
              direction="row"
              spacing={1}
              flexWrap="wrap"
              useFlexGap
            >
              <FormControl>
                <FormLabel>Protocollo</FormLabel>
                <Input
                  defaultValue={formData.protocol}
                  onChange={(event) =>
                    setFormData({ ...formData, protocol: event.target.value })
                  }
                  size="sm"
                  placeholder="PT00007"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Data Protocollo</FormLabel>
                <Input
                  defaultValue={(formData.protocolDate as string).slice(0, 10)}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      protocolDate: new Date(event.target.value).toISOString(),
                    })
                  }
                  type="date"
                  size="sm"
                  placeholder="2023/12/32"
                />
              </FormControl>
            </Stack>
            <Stack
              sx={{ mb: 1 }}
              direction="row"
              spacing={1}
              flexWrap="wrap"
              useFlexGap
            >
              <FormControl>
                <FormLabel>Ufficio Legale</FormLabel>
                <Input
                  defaultValue={formData.legalOffice}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      legalOffice: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="Via Santa Tecla 19"
                />
              </FormControl>
            </Stack>
            <Stack
              sx={{ mb: 1 }}
              direction="row"
              spacing={1}
              flexWrap="wrap"
              useFlexGap
            >
              <FormControl>
                <FormLabel>Codice Ateco</FormLabel>
                <Input
                  defaultValue={formData.atecoCode}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      atecoCode: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="AT0001"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Codice Fiscale</FormLabel>
                <Input
                  defaultValue={formData.fiscalCode}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      fiscalCode: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="CF00001"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Codice VAT</FormLabel>
                <Input
                  defaultValue={formData.vatNumber}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      vatNumber: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="VT00001"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Codice REA</FormLabel>
                <Input
                  defaultValue={formData.reaNumber}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      reaNumber: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="REA00001"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Codice SDI</FormLabel>
                <Input
                  defaultValue={formData.sdiCode}
                  onChange={(event) =>
                    setFormData({ ...formData, sdiCode: event.target.value })
                  }
                  size="sm"
                  placeholder="SD00001"
                />
              </FormControl>
            </Stack>
          </Box>
          <Box sx={{ my: 1 }}>
            <Typography level="h3">Contatti</Typography>
            <Typography level="body-md">
              Dati per comunicare con l'azienda
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Stack
              sx={{ mb: 1 }}
              direction="row"
              spacing={1}
              flexWrap="wrap"
              useFlexGap
            >
              <FormControl>
                <FormLabel>Telefono</FormLabel>
                <Input
                  defaultValue={formData.telephone}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      telephone: event.target.value,
                    })
                  }
                  size="sm"
                  type="tel"
                  startDecorator={<PhoneRounded />}
                  placeholder="+39 095 012 3456"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Cellulare</FormLabel>
                <Input
                  defaultValue={formData.cellular}
                  onChange={(event) =>
                    setFormData({ ...formData, cellular: event.target.value })
                  }
                  size="sm"
                  type="tel"
                  startDecorator={<PhoneRounded />}
                  placeholder="+39 012 345 6789"
                />
              </FormControl>
            </Stack>
            <Stack
              sx={{ mb: 1 }}
              direction="row"
              spacing={1}
              flexWrap="wrap"
              useFlexGap
            >
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  defaultValue={formData.email}
                  onChange={(event) =>
                    setFormData({ ...formData, email: event.target.value })
                  }
                  size="sm"
                  type="email"
                  startDecorator={<EmailRounded />}
                  placeholder="angelo@email.del-cugino"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Pec</FormLabel>
                <Input
                  defaultValue={formData.pec}
                  onChange={(event) =>
                    setFormData({ ...formData, pec: event.target.value })
                  }
                  size="sm"
                  type="email"
                  startDecorator={<EmailRounded />}
                  placeholder="angelo@pec.del-cugino"
                />
              </FormControl>
            </Stack>
            <Stack
              sx={{ mb: 1 }}
              direction="row"
              spacing={1}
              flexWrap="wrap"
              useFlexGap
            >
              <FormControl>
                <FormLabel>Rappesentante Legale</FormLabel>
                <Input
                  defaultValue={formData.legalRepresentative}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      legalRepresentative: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="Luigi Andatovia"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Responsabile Trattamento Dati</FormLabel>
                <Input
                  defaultValue={formData.dataProcessor}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      dataProcessor: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="Elisa Ferninando"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Titotale Trattamento Dati</FormLabel>
                <Input
                  defaultValue={formData.dataController}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      dataController: event.target.value,
                    })
                  }
                  size="sm"
                  placeholder="Letizia Spaventosa"
                />
              </FormControl>
            </Stack>
          </Box>
          <Box sx={{ my: 1 }}>
            <Typography level="h3">Amministratore</Typography>
            <Typography level="body-md">
              Informazioni identificative dell'Amministratore dell'azienda
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Stack
              sx={{ mb: 1 }}
              direction="row"
              spacing={1}
              flexWrap="wrap"
              useFlexGap
            >
              <FormControl>
                <FormLabel>Nome</FormLabel>
                <Input
                  defaultValue={formData.legalAdministrator.name}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      legalAdministrator: {
                        ...formData.legalAdministrator,
                        name: event.target.value,
                      },
                    })
                  }
                  size="sm"
                  placeholder="Francesco"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Cognome</FormLabel>
                <Input
                  defaultValue={formData.legalAdministrator.surname}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      legalAdministrator: {
                        ...formData.legalAdministrator,
                        surname: event.target.value,
                      },
                    })
                  }
                  size="sm"
                  placeholder="De Rossi"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Sesso</FormLabel>
                <Select
                  value={formData.legalAdministrator.sex}
                  onChange={(event, value) =>
                    setFormData({
                      ...formData,
                      legalAdministrator: {
                        ...formData.legalAdministrator,
                        sex: value === "m" ? "m" : "f",
                      },
                    })
                  }
                  defaultValue="m"
                  placeholder="Seleziona il sesso"
                >
                  <Option value="m">Maschio</Option>
                  <Option value="f">Femmina</Option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Codice Fiscale</FormLabel>
                <Input
                  defaultValue={formData.legalAdministrator.fiscalCode}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      legalAdministrator: {
                        ...formData.legalAdministrator,
                        fiscalCode: event.target.value,
                      },
                    })
                  }
                  size="sm"
                  placeholder="CF0000001"
                />
              </FormControl>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 1,
              gap: 1,
              alignItems: { xs: "start", sm: "center" },
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography level="h2">Servizi Attivi</Typography>
          </Box>
          {!currentCompanyData.serviceStatus.dCardAvailable && (
            <Alert color="warning" variant="soft">
              Nessun Servizio Attivo
            </Alert>
          )}
          {currentCompanyData.serviceStatus.dCardAvailable && (
            <DCardServiceInfo companyID={currentCompanyData.id} />
          )}
        </Box>
      </form>
    </Layout>
  );
}
