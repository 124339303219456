import * as React from "react";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import useScript from "hooks/useScript";
import { Layout } from "components/Layout";
import Button from "@mui/joy/Button";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router";
import DCardOwnershipTable from "components/tables/DCardOwnershipTable";
import { DCardTable } from "components/tables/DCardTable";

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export function DCards() {
  const navigate = useNavigate();
  const status = useScript(`https://unpkg.com/feather-icons`);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);

  return (
    <Layout>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: {
            xs: 2,
            md: 6,
          },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
          },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="/home"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
            <Typography fontWeight={500} fontSize={12}>
              Prodotti
            </Typography>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              dCard
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            my: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography level="h2">Abbonamenti</Typography>
          <Button
            color="primary"
            startDecorator={<Add />}
            size="sm"
            onClick={() => {
              navigate("/products/dcard/ownership/new");
            }}
          >
            Attiva il Servizio ad un'Azienda
          </Button>
        </Box>
        <DCardOwnershipTable />
        <Box
          sx={{
            display: "flex",
            my: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography level="h2">dCard</Typography>
          <Button
            color="primary"
            startDecorator={<Add />}
            size="sm"
            onClick={() => {
              navigate("/products/dcard/new");
            }}
          >
            Crea
          </Button>
        </Box>
        <DCardTable />
      </Box>
    </Layout>
  );
}
