/* tslint:disable */
/* eslint-disable */
/**
 * dManager API
 * Managment infrastracture for Digital Motus
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Administrator
 */
export interface Administrator {
    /**
     * 
     * @type {number}
     * @memberof Administrator
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'surname': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'sex': AdministratorSexEnum;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'fiscalCode': string;
}

export const AdministratorSexEnum = {
    M: 'm',
    F: 'f'
} as const;

export type AdministratorSexEnum = typeof AdministratorSexEnum[keyof typeof AdministratorSexEnum];

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'legalForm': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'atecoCode': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'protocol': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'protocolDate': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'capital': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'legalOffice': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'fiscalCode': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'vatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'reaNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'sdiCode': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'telephone': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'cellular': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'pec': string;
    /**
     * 
     * @type {Administrator}
     * @memberof Company
     */
    'legalAdministrator': Administrator;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'legalRepresentative': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'dataProcessor': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'dataController': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'updatedDate': string;
    /**
     * 
     * @type {ServiceStatus}
     * @memberof Company
     */
    'serviceStatus': ServiceStatus;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'legalForm': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'atecoCode': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'protocol': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'protocolDate': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'capital': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'legalOffice': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'fiscalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'vatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'reaNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'sdiCode': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'telephone': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'cellular': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'pec': string;
    /**
     * 
     * @type {Administrator}
     * @memberof CompanyDto
     */
    'legalAdministrator': Administrator;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'legalRepresentative': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'dataProcessor': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'dataController': string;
    /**
     * 
     * @type {ServiceStatus}
     * @memberof CompanyDto
     */
    'serviceStatus': ServiceStatus;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'updatedDate': string;
}
/**
 * 
 * @export
 * @interface CreateAdministratorDTO
 */
export interface CreateAdministratorDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateAdministratorDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdministratorDTO
     */
    'surname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdministratorDTO
     */
    'sex': CreateAdministratorDTOSexEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAdministratorDTO
     */
    'fiscalCode': string;
}

export const CreateAdministratorDTOSexEnum = {
    M: 'm',
    F: 'f'
} as const;

export type CreateAdministratorDTOSexEnum = typeof CreateAdministratorDTOSexEnum[keyof typeof CreateAdministratorDTOSexEnum];

/**
 * 
 * @export
 * @interface CreateCompanyDto
 */
export interface CreateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'legalForm': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'atecoCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'protocol': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'protocolDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'capital': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'legalOffice': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'fiscalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'vatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'reaNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'sdiCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'telephone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'cellular': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'pec': string;
    /**
     * 
     * @type {CreateAdministratorDTO}
     * @memberof CreateCompanyDto
     */
    'legalAdministrator': CreateAdministratorDTO;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'legalRepresentative': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'dataProcessor': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'dataController': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CreateDCardDto
 */
export interface CreateDCardDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDCardDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDCardDto
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface DCard
 */
export interface DCard {
    /**
     * 
     * @type {string}
     * @memberof DCard
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof DCard
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DCard
     */
    'link': string;
    /**
     * 
     * @type {boolean}
     * @memberof DCard
     */
    'isActive': boolean;
    /**
     * 
     * @type {DCardOwnership}
     * @memberof DCard
     */
    'ownership': DCardOwnership;
}
/**
 * 
 * @export
 * @interface DCardOwnership
 */
export interface DCardOwnership {
    /**
     * 
     * @type {number}
     * @memberof DCardOwnership
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DCardOwnership
     */
    'companyId': number;
    /**
     * 
     * @type {Array<DCard>}
     * @memberof DCardOwnership
     */
    'dcards': Array<DCard>;
}
/**
 * 
 * @export
 * @interface LoginDTO
 */
export interface LoginDTO {
    /**
     * 
     * @type {string}
     * @memberof LoginDTO
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ServiceStatus
 */
export interface ServiceStatus {
    /**
     * 
     * @type {number}
     * @memberof ServiceStatus
     */
    'id': number;
    /**
     * 
     * @type {Company}
     * @memberof ServiceStatus
     */
    'company': Company;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceStatus
     */
    'dCardAvailable': boolean;
}
/**
 * 
 * @export
 * @interface TokenDTO
 */
export interface TokenDTO {
    /**
     * 
     * @type {string}
     * @memberof TokenDTO
     */
    'access_token': string;
}
/**
 * 
 * @export
 * @interface UpdateAdministorDto
 */
export interface UpdateAdministorDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdministorDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdministorDto
     */
    'surname': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdministorDto
     */
    'sex': UpdateAdministorDtoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdministorDto
     */
    'fiscalCode': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdministorDto
     */
    'id': number;
}

export const UpdateAdministorDtoSexEnum = {
    M: 'm',
    F: 'f'
} as const;

export type UpdateAdministorDtoSexEnum = typeof UpdateAdministorDtoSexEnum[keyof typeof UpdateAdministorDtoSexEnum];

/**
 * 
 * @export
 * @interface UpdateCompanyDto
 */
export interface UpdateCompanyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'legalForm': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'atecoCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'protocol': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'protocolDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'capital': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'legalOffice': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'fiscalCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'vatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'reaNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'sdiCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'telephone': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'cellular': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'pec': string;
    /**
     * 
     * @type {UpdateAdministorDto}
     * @memberof UpdateCompanyDto
     */
    'legalAdministrator': UpdateAdministorDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'legalRepresentative': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'dataProcessor': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'dataController': string;
}
/**
 * 
 * @export
 * @interface UpdateDCardDto
 */
export interface UpdateDCardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDCardDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDCardDto
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDCardDto
     */
    'uuid': string;
}

/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Company
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreate: async (createCompanyDto: CreateCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyDto' is not null or undefined
            assertParamExists('companyControllerCreate', 'createCompanyDto', createCompanyDto)
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Company
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindOneByOrFail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerFindOneByOrFail', 'id', id)
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Company
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerRemove', 'id', id)
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Company
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdate: async (updateCompanyDto: UpdateCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyDto' is not null or undefined
            assertParamExists('companyControllerUpdate', 'updateCompanyDto', updateCompanyDto)
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Company
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerCreate(createCompanyDto: CreateCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerCreate(createCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Company
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerFindOneByOrFail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerFindOneByOrFail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Company
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Company
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerUpdate(updateCompanyDto: UpdateCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdate(updateCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Company
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreate(createCompanyDto: CreateCompanyDto, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.companyControllerCreate(createCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAll(options?: any): AxiosPromise<Array<CompanyDto>> {
            return localVarFp.companyControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Company
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindOneByOrFail(id: number, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.companyControllerFindOneByOrFail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Company
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerRemove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.companyControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Company
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdate(updateCompanyDto: UpdateCompanyDto, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.companyControllerUpdate(updateCompanyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary Create a Company
     * @param {CreateCompanyDto} createCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerCreate(createCompanyDto: CreateCompanyDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerCreate(createCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerFindAll(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Company
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerFindOneByOrFail(id: number, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerFindOneByOrFail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Company
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerRemove(id: number, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Company
     * @param {UpdateCompanyDto} updateCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerUpdate(updateCompanyDto: UpdateCompanyDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerUpdate(updateCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DCardApi - axios parameter creator
 * @export
 */
export const DCardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerActivate: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('dCardApiControllerActivate', 'uuid', uuid)
            const localVarPath = `/dcard/{uuid}/activate`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a dCard
         * @param {CreateDCardDto} createDCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerCreate: async (createDCardDto: CreateDCardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDCardDto' is not null or undefined
            assertParamExists('dCardApiControllerCreate', 'createDCardDto', createDCardDto)
            const localVarPath = `/dcard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dectivate a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerDeactivate: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('dCardApiControllerDeactivate', 'uuid', uuid)
            const localVarPath = `/dcard/{uuid}/deactivate`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerFetch: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('dCardApiControllerFetch', 'uuid', uuid)
            const localVarPath = `/dcard/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all dCards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerFetchAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dcard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerRemove: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('dCardApiControllerRemove', 'uuid', uuid)
            const localVarPath = `/dcard/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a dCard
         * @param {UpdateDCardDto} updateDCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerUpdate: async (updateDCardDto: UpdateDCardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDCardDto' is not null or undefined
            assertParamExists('dCardApiControllerUpdate', 'updateDCardDto', updateDCardDto)
            const localVarPath = `/dcard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DCardApi - functional programming interface
 * @export
 */
export const DCardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DCardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardApiControllerActivate(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardApiControllerActivate(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a dCard
         * @param {CreateDCardDto} createDCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardApiControllerCreate(createDCardDto: CreateDCardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardApiControllerCreate(createDCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dectivate a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardApiControllerDeactivate(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardApiControllerDeactivate(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardApiControllerFetch(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardApiControllerFetch(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all dCards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardApiControllerFetchAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DCard>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardApiControllerFetchAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardApiControllerRemove(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardApiControllerRemove(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a dCard
         * @param {UpdateDCardDto} updateDCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardApiControllerUpdate(updateDCardDto: UpdateDCardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardApiControllerUpdate(updateDCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DCardApi - factory interface
 * @export
 */
export const DCardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DCardApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerActivate(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.dCardApiControllerActivate(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a dCard
         * @param {CreateDCardDto} createDCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerCreate(createDCardDto: CreateDCardDto, options?: any): AxiosPromise<DCard> {
            return localVarFp.dCardApiControllerCreate(createDCardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dectivate a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerDeactivate(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.dCardApiControllerDeactivate(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerFetch(uuid: string, options?: any): AxiosPromise<DCard> {
            return localVarFp.dCardApiControllerFetch(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all dCards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerFetchAll(options?: any): AxiosPromise<Array<DCard>> {
            return localVarFp.dCardApiControllerFetchAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a dCard
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerRemove(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.dCardApiControllerRemove(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a dCard
         * @param {UpdateDCardDto} updateDCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardApiControllerUpdate(updateDCardDto: UpdateDCardDto, options?: any): AxiosPromise<DCard> {
            return localVarFp.dCardApiControllerUpdate(updateDCardDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DCardApi - object-oriented interface
 * @export
 * @class DCardApi
 * @extends {BaseAPI}
 */
export class DCardApi extends BaseAPI {
    /**
     * 
     * @summary Activate a dCard
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardApi
     */
    public dCardApiControllerActivate(uuid: string, options?: AxiosRequestConfig) {
        return DCardApiFp(this.configuration).dCardApiControllerActivate(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a dCard
     * @param {CreateDCardDto} createDCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardApi
     */
    public dCardApiControllerCreate(createDCardDto: CreateDCardDto, options?: AxiosRequestConfig) {
        return DCardApiFp(this.configuration).dCardApiControllerCreate(createDCardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dectivate a dCard
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardApi
     */
    public dCardApiControllerDeactivate(uuid: string, options?: AxiosRequestConfig) {
        return DCardApiFp(this.configuration).dCardApiControllerDeactivate(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a dCard
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardApi
     */
    public dCardApiControllerFetch(uuid: string, options?: AxiosRequestConfig) {
        return DCardApiFp(this.configuration).dCardApiControllerFetch(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all dCards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardApi
     */
    public dCardApiControllerFetchAll(options?: AxiosRequestConfig) {
        return DCardApiFp(this.configuration).dCardApiControllerFetchAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a dCard
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardApi
     */
    public dCardApiControllerRemove(uuid: string, options?: AxiosRequestConfig) {
        return DCardApiFp(this.configuration).dCardApiControllerRemove(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a dCard
     * @param {UpdateDCardDto} updateDCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardApi
     */
    public dCardApiControllerUpdate(updateDCardDto: UpdateDCardDto, options?: AxiosRequestConfig) {
        return DCardApiFp(this.configuration).dCardApiControllerUpdate(updateDCardDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DCardOwnershipApi - axios parameter creator
 * @export
 */
export const DCardOwnershipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate dCard Service by Company ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerActivate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dCardOwnershipApiControllerActivate', 'id', id)
            const localVarPath = `/dcard-ownership/activate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a dCard to an Owenership
         * @param {number} id 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerAddCard: async (id: number, uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dCardOwnershipApiControllerAddCard', 'id', id)
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('dCardOwnershipApiControllerAddCard', 'uuid', uuid)
            const localVarPath = `/dcard-ownership/{id}/add/{uuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate dCard Service by Company ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerDeactivate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dCardOwnershipApiControllerDeactivate', 'id', id)
            const localVarPath = `/dcard-ownership/deactivate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a dCard Owenership by Company ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerFetch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dCardOwnershipApiControllerFetch', 'id', id)
            const localVarPath = `/dcard-ownership/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a dCard from an Owenership
         * @param {number} id 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerRemoveCard: async (id: number, uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dCardOwnershipApiControllerRemoveCard', 'id', id)
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('dCardOwnershipApiControllerRemoveCard', 'uuid', uuid)
            const localVarPath = `/dcard-ownership/{id}/remove/{uuid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DCardOwnershipApi - functional programming interface
 * @export
 */
export const DCardOwnershipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DCardOwnershipApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate dCard Service by Company ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardOwnershipApiControllerActivate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardOwnershipApiControllerActivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a dCard to an Owenership
         * @param {number} id 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardOwnershipApiControllerAddCard(id: number, uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCardOwnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardOwnershipApiControllerAddCard(id, uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate dCard Service by Company ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardOwnershipApiControllerDeactivate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardOwnershipApiControllerDeactivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a dCard Owenership by Company ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardOwnershipApiControllerFetch(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCardOwnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardOwnershipApiControllerFetch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a dCard from an Owenership
         * @param {number} id 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dCardOwnershipApiControllerRemoveCard(id: number, uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCardOwnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dCardOwnershipApiControllerRemoveCard(id, uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DCardOwnershipApi - factory interface
 * @export
 */
export const DCardOwnershipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DCardOwnershipApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate dCard Service by Company ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerActivate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.dCardOwnershipApiControllerActivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a dCard to an Owenership
         * @param {number} id 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerAddCard(id: number, uuid: string, options?: any): AxiosPromise<DCardOwnership> {
            return localVarFp.dCardOwnershipApiControllerAddCard(id, uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate dCard Service by Company ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerDeactivate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.dCardOwnershipApiControllerDeactivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a dCard Owenership by Company ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerFetch(id: number, options?: any): AxiosPromise<DCardOwnership> {
            return localVarFp.dCardOwnershipApiControllerFetch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a dCard from an Owenership
         * @param {number} id 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dCardOwnershipApiControllerRemoveCard(id: number, uuid: string, options?: any): AxiosPromise<DCardOwnership> {
            return localVarFp.dCardOwnershipApiControllerRemoveCard(id, uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DCardOwnershipApi - object-oriented interface
 * @export
 * @class DCardOwnershipApi
 * @extends {BaseAPI}
 */
export class DCardOwnershipApi extends BaseAPI {
    /**
     * 
     * @summary Activate dCard Service by Company ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardOwnershipApi
     */
    public dCardOwnershipApiControllerActivate(id: number, options?: AxiosRequestConfig) {
        return DCardOwnershipApiFp(this.configuration).dCardOwnershipApiControllerActivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a dCard to an Owenership
     * @param {number} id 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardOwnershipApi
     */
    public dCardOwnershipApiControllerAddCard(id: number, uuid: string, options?: AxiosRequestConfig) {
        return DCardOwnershipApiFp(this.configuration).dCardOwnershipApiControllerAddCard(id, uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate dCard Service by Company ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardOwnershipApi
     */
    public dCardOwnershipApiControllerDeactivate(id: number, options?: AxiosRequestConfig) {
        return DCardOwnershipApiFp(this.configuration).dCardOwnershipApiControllerDeactivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a dCard Owenership by Company ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardOwnershipApi
     */
    public dCardOwnershipApiControllerFetch(id: number, options?: AxiosRequestConfig) {
        return DCardOwnershipApiFp(this.configuration).dCardOwnershipApiControllerFetch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a dCard from an Owenership
     * @param {number} id 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCardOwnershipApi
     */
    public dCardOwnershipApiControllerRemoveCard(id: number, uuid: string, options?: AxiosRequestConfig) {
        return DCardOwnershipApiFp(this.configuration).dCardOwnershipApiControllerRemoveCard(id, uuid, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DManagerAuthApi - axios parameter creator
 * @export
 */
export const DManagerAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginDTO} loginDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managerAuthControllerLogin: async (loginDTO: LoginDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDTO' is not null or undefined
            assertParamExists('managerAuthControllerLogin', 'loginDTO', loginDTO)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DManagerAuthApi - functional programming interface
 * @export
 */
export const DManagerAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DManagerAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginDTO} loginDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managerAuthControllerLogin(loginDTO: LoginDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managerAuthControllerLogin(loginDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DManagerAuthApi - factory interface
 * @export
 */
export const DManagerAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DManagerAuthApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginDTO} loginDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managerAuthControllerLogin(loginDTO: LoginDTO, options?: any): AxiosPromise<TokenDTO> {
            return localVarFp.managerAuthControllerLogin(loginDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DManagerAuthApi - object-oriented interface
 * @export
 * @class DManagerAuthApi
 * @extends {BaseAPI}
 */
export class DManagerAuthApi extends BaseAPI {
    /**
     * 
     * @param {LoginDTO} loginDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DManagerAuthApi
     */
    public managerAuthControllerLogin(loginDTO: LoginDTO, options?: AxiosRequestConfig) {
        return DManagerAuthApiFp(this.configuration).managerAuthControllerLogin(loginDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



