import { DCardOwnershipDeleteModal } from "components/modals/DCardOwnershipDeleteModal";
import Button from "@mui/joy/Button/Button";
import React from "react";

interface DCardOwnershipDeleteButtonProps {
  companyId: number;
  onDelete?: Function;
}

export function DCardOwnershipDeleteButton({
  companyId,
  onDelete,
}: DCardOwnershipDeleteButtonProps) {
  const [open, setOpen] = React.useState(false);

  function handleOnClose() {
    setOpen(false);
  }

  async function handleOnDelete() {
    if (typeof onDelete !== "undefined") await onDelete();
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button variant="soft" color="danger" onClick={() => setOpen(true)}>
        Disattiva Servizio
      </Button>
      <DCardOwnershipDeleteModal
        companyId={companyId}
        open={open}
        onClose={handleOnClose}
        onDelete={handleOnDelete}
      />
    </React.Fragment>
  );
}
