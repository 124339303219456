import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Skeleton from "@mui/joy/Skeleton/Skeleton";
import Typography from "@mui/joy/Typography";

interface CompanyCountCardProp {
  loading: boolean;
  count: number;
}

export function CompanyCountCard({
  loading,
  count,
}: CompanyCountCardProp) {
  return (
    <Card variant="soft" color="neutral" invertedColors>
      <CardContent orientation="horizontal">
        <CardContent>
          <Typography level="body-md">Aziende clienti</Typography>
          <Skeleton height="100%" loading={loading} variant="rectangular">
            <Typography level="h2">{count}</Typography>
          </Skeleton>
        </CardContent>
      </CardContent>
    </Card>
  );
}
