import { Preferences } from "@capacitor/preferences";
import {
  DManagerAuthApi,
  CompanyApi,
  DCardApi,
  DCardOwnershipApi,
  Configuration,
} from "./openapi";
import axios, { AxiosInstance } from "axios";

interface AuthProvider {
  isAuthenticated(): Promise<boolean>;
  signin(password: string): Promise<void>;
  signout(): Promise<void>;
}

const basePath = "http://212.227.145.140:3000";
const ACCESS_TOKEN_KEY = "access_token_key";

const axiosInstance: AxiosInstance = axios.create({
  timeout: 1.5 * 60 * 1000,
  timeoutErrorMessage: "timeout",
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

const config = new Configuration({
  accessToken: (await Preferences.get({ key: ACCESS_TOKEN_KEY })).value || "",
});

const authApi = new DManagerAuthApi(undefined, basePath, axiosInstance);

export const authProvider: AuthProvider = {
  async isAuthenticated() {
    const keys = await Preferences.keys();
    return keys.keys.includes(ACCESS_TOKEN_KEY);
  },
  async signin(password: string) {
    const request = await authApi.managerAuthControllerLogin({ password });
    if (request.status === 201) {
      const { access_token } = request.data;
      await Preferences.set({ key: ACCESS_TOKEN_KEY, value: access_token });
      config.accessToken = access_token;
    }
  },
  async signout() {
    await Preferences.remove({ key: ACCESS_TOKEN_KEY });
    config.accessToken = "";
  },
};

export const companyApi = new CompanyApi(config, basePath, axiosInstance);
export const cardApi = new DCardApi(config, basePath, axiosInstance);
export const cardOwnershipApi = new DCardOwnershipApi(
  config,
  basePath,
  axiosInstance
);
