import { CompanyDeleteButton } from "components/buttons/company/CompanyDeleteButton";
import { CompanyOpenButton } from "components/buttons/company/CompanyOpenButton";
import Avatar from "@mui/joy/Avatar/Avatar";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography/Typography";
import { CompanyDto } from "services/openapi";

interface CompanyRowProps {
  company: CompanyDto;
  onDelete?: Function;
}

export function CompanyRow({ company, onDelete }: CompanyRowProps) {
  return (
    <tr key={company.id}>
      <td style={{ textAlign: "center" }}>
        <Typography level="body-xs">{company.id}</Typography>
      </td>
      <td>
        <Typography level="body-xs">{company.companyName}</Typography>
      </td>
      <td>
        <Typography level="body-xs">{company.cellular}</Typography>
      </td>
      <td>
        <Typography level="body-xs">{company.email}</Typography>
      </td>
      <td>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Avatar size="sm">{company.legalAdministrator.name[0]}</Avatar>
          <div>
            <Typography level="body-xs">
              {company.legalAdministrator.name}
            </Typography>
            <Typography level="body-xs">
              {company.legalAdministrator.fiscalCode}
            </Typography>
          </div>
        </Box>
      </td>
      <td>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "end",
          }}
        >
          <CompanyOpenButton companyId={company.id} />
          <CompanyDeleteButton companyId={company.id} onDelete={onDelete} />
        </Box>
      </td>
    </tr>
  );
}
