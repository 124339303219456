import { DCardOwnershipDeleteButton } from "components/buttons/dcard-ownership/DCadOwnershipDeleteButton";
import { DCardOwnershipOpenButton } from "components/buttons/dcard-ownership/DCardOwnershipOpenButton";
import { Box, Typography } from "@mui/joy";
import { CompanyDto } from "services/openapi";

interface DCardOwnershipDataRowProps {
  company: CompanyDto;
  onDelete: Function;
}

export function DCardOwnershipDataRow({
  company,
  onDelete,
}: DCardOwnershipDataRowProps) {
  return (
    <tr key={company.id}>
      <td style={{ paddingLeft: "16px", zIndex: 2 }}>
        <Typography level="body-xs">{company.id}</Typography>
      </td>
      <td>
        <Typography level="body-xs">{company.companyName}</Typography>
      </td>
      <td>
        <Typography level="body-xs">{company.cellular}</Typography>
      </td>
      <td>
        <Typography level="body-xs">{company.email}</Typography>
      </td>
      <td>
        <Typography level="body-xs">
          {company.legalAdministrator.name +
            " " +
            company.legalAdministrator.surname}
        </Typography>
      </td>
      <td style={{ zIndex: 2 }}>
        <Box sx={{ display: "flex", gap: 1, justifyContent: "end" }}>
          <DCardOwnershipOpenButton companyId={company.id} />
          <DCardOwnershipDeleteButton
            companyId={company.id}
            onDelete={onDelete}
          />
        </Box>
      </td>
    </tr>
  );
}
