export function CompanyHeaderRow() {
  return (
    <tr>
      <th style={{ textAlign: "center", width: 50, padding: "12px 6px" }}>
        ID
      </th>
      <th style={{ width: "auto", padding: "12px 6px" }}>Azienda</th>
      <th style={{ width: "auto", padding: "12px 6px" }}>Cellulare</th>
      <th style={{ width: "auto", padding: "12px 6px" }}>Email</th>
      <th style={{ width: "auto", padding: "12px 6px" }}>Amministratore</th>
      <th style={{ width: "auto", padding: "12px 6px" }}></th>
    </tr>
  );
}
