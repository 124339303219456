import { CompanyDto } from "services/openapi";
import { CompanyNoDataRow } from "./CompanyNoDataRow";
import { CompanyRow } from "./CompanyRow";
import React from "react";

interface CompanyTableContentProps {
  companies: CompanyDto[];
  onCompanyDelete: Function;
}

export function CompanyTableContent({
  companies,
  onCompanyDelete,
}: CompanyTableContentProps) {
  return (
    <React.Fragment>{companies.length === 0 ? (
      <CompanyNoDataRow />
    ) : (
      companies.map((company) => (
        <CompanyRow
          key={"company-data-" + company.id}
          company={company}
          onDelete={onCompanyDelete}
        />
      ))
    )}</React.Fragment>);
}
