import { DCard } from "services/openapi";
import { DCardActivateButton } from "./DCardActivateButton";
import { DCardDeactivateButton } from "./DCardDeactivateButton";

interface DCardToggleActivationButtonProps {
  dcard: DCard;
  onToggle?: Function;
}

export function DCardToggleActivationButton({ dcard, onToggle }: DCardToggleActivationButtonProps) {
  return dcard.isActive
    ? <DCardDeactivateButton cardUuid={dcard.uuid} onDeactivate={onToggle} />
    : <DCardActivateButton cardUuid={dcard.uuid} onActivate={onToggle} />;
}