import { DCardDeleteModal } from "components/modals/DCardDeleteModal";
import Button from "@mui/joy/Button/Button";
import React from "react";

interface DCardDeleteButtonProps {
  dcardUuid: string;
  onDelete?: Function;
}

export function DCardDeleteButton({
  dcardUuid,
  onDelete,
}: DCardDeleteButtonProps) {
  const [open, setOpen] = React.useState(false);

  function handleOnClose() {
    setOpen(false);
  }

  async function handleOnDelete() {
    if (typeof onDelete !== "undefined") await onDelete();
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button variant="soft" color="danger" onClick={() => setOpen(true)}>
        Elimina
      </Button>
      <DCardDeleteModal
        dcardUuid={dcardUuid}
        open={open}
        onClose={handleOnClose}
        onDelete={handleOnDelete}
      />
    </React.Fragment>
  );
}
