import Button from "@mui/joy/Button/Button";

interface DCardOwnershipOpenButtonProps {
  companyId: number;
}

export function DCardOwnershipOpenButton({
  companyId
}: DCardOwnershipOpenButtonProps) {
  const companyOpenRoute = "/company/" + companyId;

  return (
    <Button
      component="a"
      href={companyOpenRoute}
      variant="soft"
      color="neutral"
    >
      Apri Abbonamento
    </Button>
  );
}
