import Button from "@mui/joy/Button/Button";

interface CompanyOpenButtonProps {
  companyId: number;
}

export function CompanyOpenButton({ companyId }: CompanyOpenButtonProps) {
  const companyOpenRoute = "/company/" + companyId;

  return (
    <Button
      component="a"
      href={companyOpenRoute}
      variant="soft"
      color="neutral"
    >
      Apri
    </Button>
  );
}
