import { companyLoader } from "router/loaders";
import Aziende from "views/company/Companies";
import ModificaAzienda from "views/company/Company";
import CreaAzienda from "views/company/NewCompany";
import { RouteObject } from "react-router-dom";

export const CompanyRoute: RouteObject = {
  path: "company",
  children: [
    {
      index: true,
      Component: Aziende,
    },
    {
      path: ":companyId",
      loader: companyLoader,
      Component: ModificaAzienda,
    },
    {
      path: "new",
      Component: CreaAzienda,
    },
  ],
};
