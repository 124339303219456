export function DCardOwnershipHeaderRow() {
  return (
    <tr>
      <th
        style={{
          width: "48px",
          padding: "12px 16px",
        }}
      >
        ID
      </th>
      <th style={{ width: 140, padding: "12px 6px" }}>Azienda</th>
      <th style={{ width: 140, padding: "12px 6px" }}>Cellular</th>
      <th style={{ width: 180, padding: "12px 6px" }}>Email</th>
      <th style={{ width: 140, padding: "12px 6px" }}>Nominativo</th>
      <th aria-label="last" style={{ width: "180px" }} />
    </tr>
  );
}
