import Block from "@mui/icons-material/Block";
import CheckRounded from "@mui/icons-material/CheckRounded"
import Chip from "@mui/joy/Chip/Chip"

interface DCardActivationStatusChipProps {
    isActive: boolean
}

export function DCardActivationStatusChip({ isActive }: DCardActivationStatusChipProps) {
    return isActive
        ? <DCardActiveChip />
        : <DCardDeactiveChip />
}

function DCardActiveChip() {
    return (
        <Chip
            variant="soft"
            size="sm"
            startDecorator={<CheckRounded />}
            color="success"
        >
            Attivata
        </Chip>
    )
}


function DCardDeactiveChip() {
    return (<Chip
        variant="soft"
        size="sm"
        startDecorator={<Block />}
        color="danger"
    >
        Disattivata
    </Chip>);
}