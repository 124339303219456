import Button from "@mui/joy/Button/Button";

interface DCardOpenButtonProps {
  dcardUuid: string;
}

export function DCardOpenButton({ dcardUuid }: DCardOpenButtonProps) {
  const dcardOpenRoute = "/products/dcard/" + dcardUuid;

  return (
    <Button
      component="a"
      href={dcardOpenRoute}
      variant="soft"
      color="neutral"
    >
      Apri DCard
    </Button>
  );
}
