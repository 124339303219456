import { Skeleton } from "@mui/joy";
import Card from "@mui/joy/Card/Card";
import CardContent from "@mui/joy/CardContent/CardContent";
import Typography from "@mui/joy/Typography/Typography";
import { PieChart } from "@mui/x-charts/PieChart";

interface DCardPieCardProps {
  loading: boolean;
  assignedCount: number;
  unassingned: number;
}

export function DCardPieCard({
  loading,
  assignedCount,
  unassingned,
}: DCardPieCardProps) {
  return (
    <Card variant="soft" color="neutral">
      <CardContent orientation="horizontal">
        <Skeleton
          height={90}
          width={90}
          loading={loading}
          variant="rectangular"
        >
          <PieChart
            height={90}
            width={90}
            series={[
              {
                data: [
                  { value: assignedCount, color: "blue" },
                  { value: unassingned, color: "grey" },
                ],
                innerRadius: 35,
                outerRadius: 25,
                paddingAngle: 10,
                cornerRadius: 3,
                startAngle: 0,
                cx: 40,
                cy: 40,
              },
            ]}
          />
        </Skeleton>
        <CardContent>
          <Typography level="body-md">dCard assegnate</Typography>
          <Skeleton height="100%" loading={loading} variant="rectangular">
            <Typography level="h2">
              {assignedCount} su {assignedCount + unassingned}
            </Typography>
          </Skeleton>
        </CardContent>
      </CardContent>
    </Card>
  );
}
