import { CompanyDto } from "services/openapi";
import { DCardOwnershipNoDataRow } from "./DCardOwnershipNoDataRow";
import { DCardOwnershipDataRow } from "./DCardOwnershipRow";
import React from "react";

interface DCardOwnershipTableContentProps {
  companies: CompanyDto[];
  onDCardOwnershipDelete: Function;
}

export function DCardOwnershipTableContent({
  companies,
  onDCardOwnershipDelete,
}: DCardOwnershipTableContentProps) {
  return (
    <React.Fragment>
      {companies.length === 0 ? (
        <DCardOwnershipNoDataRow />
      ) : (
        companies.map((company) => (
          <DCardOwnershipDataRow
            key={"company-data-" + company.id}
            company={company}
            onDelete={onDCardOwnershipDelete}
          />
        ))
      )}
    </React.Fragment>
  );
}
