import * as React from "react";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import useScript from "hooks/useScript";
import { Layout } from "components/Layout";
import Button from "@mui/joy/Button";
import { EmailRounded, Info, Key, PhoneRounded } from "@mui/icons-material";
import {
  Divider,
  FormControl,
  FormLabel,
  Input,
  LinearProgress,
  Sheet,
  Stack,
} from "@mui/joy";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { useNavigate } from "react-router";
import { companyApi } from "services/api";
import { CreateCompanyDto } from "services/openapi/api";

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export default function CreaAzienda() {
  const status = useScript(`https://unpkg.com/feather-icons`);
  const [formData, setFormData] = React.useState<CreateCompanyDto>({
    password: "",
  } as CreateCompanyDto);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [created, setCreated] = React.useState(false);
  const navigate = useNavigate();

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);

  React.useEffect(() => {
    companyApi
      .companyControllerFindAll()
      .then((r) => console.log(r))
      .catch((e) => console.debug(e));
  }, []);

  return (
    <Layout>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          console.log(formData);
          setIsSubmitting(true);
          companyApi
            .companyControllerCreate(formData)
            .then(() => setCreated(true))
            .catch((_) => alert("Errore, inserisci tutti i dati"))
            .finally(() => setIsSubmitting(false));
        }}
      >
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: {
              xs: 2,
              md: 6,
            },
            pt: {
              xs: "calc(12px + var(--Header-height))",
              sm: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="#some-link"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="#some-link"
                fontSize={12}
                fontWeight={500}
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="/company"
                fontSize={12}
                fontWeight={500}
              >
                Aziende
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Crea
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 1,
              gap: 1,
              alignItems: { xs: "start", sm: "center" },
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography level="h2">Crea Azienda</Typography>
          </Box>
          <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
              width: "100%",
              borderRadius: "sm",
              flexShrink: 1,
              overflow: "auto",
              minHeight: 0,
              mb: {
                md: 5,
                xs: 12,
              },
            }}
          >
            <Box sx={{ mb: 1, padding: "14px" }}>
              <Typography level="title-md">Azienda</Typography>
              <Typography level="body-sm">
                Informazioni identificative dell'azienda
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ mb: 1, padding: "14px" }}>
              <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                <FormControl>
                  <FormLabel>Nome Azienda</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        companyName: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="Pasticceria Nino d'Angelo"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Forma Legale</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        legalForm: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="srl"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Codice Ateco</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        atecoCode: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="AT0001"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Protocollo</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({ ...formData, protocol: event.target.value })
                    }
                    size="sm"
                    placeholder="PT00007"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Data Protocollo</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        protocolDate: new Date(
                          event.target.value
                        ).toISOString(),
                      })
                    }
                    type="date"
                    size="sm"
                    placeholder="2023/12/32"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Capitale</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({ ...formData, capital: event.target.value })
                    }
                    startDecorator="€"
                    size="sm"
                    placeholder="100.000"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Ufficio Legale</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        legalOffice: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="Via Santa Tecla 19"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Codice Fiscale</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        fiscalCode: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="CF00001"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Codice VAT</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        vatNumber: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="VT00001"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Codice REA</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        reaNumber: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="REA00001"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Codice SDI</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({ ...formData, sdiCode: event.target.value })
                    }
                    size="sm"
                    placeholder="SD00001"
                  />
                </FormControl>
              </Stack>
            </Box>
            <Divider />
            <Box sx={{ mb: 1, padding: "14px" }}>
              <Typography level="title-md">Contatti</Typography>
              <Typography level="body-sm">
                Dati per comunicare con l'azienda
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ mb: 1, padding: "14px" }}>
              <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({ ...formData, email: event.target.value })
                    }
                    size="sm"
                    type="email"
                    startDecorator={<EmailRounded />}
                    placeholder="angelo@email.del-cugino"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Telefono</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        telephone: event.target.value,
                      })
                    }
                    size="sm"
                    type="tel"
                    startDecorator={<PhoneRounded />}
                    placeholder="+39 095 012 3456"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Cellulare</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({ ...formData, cellular: event.target.value })
                    }
                    size="sm"
                    type="tel"
                    startDecorator={<PhoneRounded />}
                    placeholder="+39 012 345 6789"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Pec</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({ ...formData, pec: event.target.value })
                    }
                    size="sm"
                    type="email"
                    startDecorator={<EmailRounded />}
                    placeholder="angelo@pec.del-cugino"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Rappesentante Legale</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        legalRepresentative: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="Luigi Andatovia"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Responsabile Trattamento Dati</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        dataProcessor: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="Elisa Ferninando"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Titotale Trattamento Dati</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        dataController: event.target.value,
                      })
                    }
                    size="sm"
                    placeholder="Letizia Spaventosa"
                  />
                </FormControl>
              </Stack>
            </Box>
            <Divider />
            <Box sx={{ mb: 1, padding: "14px" }}>
              <Typography level="title-md">Amministratore</Typography>
              <Typography level="body-sm">
                Informazioni identificative dell'Amministratore dell'azienda
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ mb: 1, padding: "14px" }}>
              <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                <FormControl>
                  <FormLabel>Nome</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        legalAdministrator: {
                          ...formData.legalAdministrator,
                          name: event.target.value,
                        },
                      })
                    }
                    size="sm"
                    placeholder="Francesco"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Cognome</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        legalAdministrator: {
                          ...formData.legalAdministrator,
                          surname: event.target.value,
                        },
                      })
                    }
                    size="sm"
                    placeholder="De Rossi"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Sesso</FormLabel>
                  <Select
                    onChange={(event, value) =>
                      setFormData({
                        ...formData,
                        legalAdministrator: {
                          ...formData.legalAdministrator,
                          sex: value === "m" ? "m" : "f",
                        },
                      })
                    }
                    defaultValue="m"
                    placeholder="Seleziona il sesso"
                  >
                    <Option value="m">Maschio</Option>
                    <Option value="f">Femmina</Option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Codice Fiscale</FormLabel>
                  <Input
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        legalAdministrator: {
                          ...formData.legalAdministrator,
                          fiscalCode: event.target.value,
                        },
                      })
                    }
                    size="sm"
                    placeholder="CF0000001"
                  />
                </FormControl>
              </Stack>
            </Box>
            <Divider />
            <Box sx={{ mb: 1, padding: "14px" }}>
              <Typography level="title-md">Credenziali</Typography>
              <Typography level="body-sm">
                Credenziali per far accedere l'azienda alla sua area personale
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ mb: 0, padding: "14px" }}>
              <Box
                sx={{
                  mb: 1,
                  mx: 0.5,
                }}
              >
                <Typography
                  variant="soft"
                  color="neutral"
                  startDecorator={<Info />}
                  fontSize="sm"
                  sx={{ "--Typography-gap": "0.5rem", p: 1 }}
                >
                  L'azienda potrà accedere con la password che inserisci e
                  l'email che hai fornito precedentemente
                </Typography>
              </Box>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Stack
                  spacing={0.5}
                  sx={{
                    "--hue": Math.min(formData.password.length * 10, 120),
                  }}
                >
                  <Input
                    onChange={(event) =>
                      setFormData({ ...formData, password: event.target.value })
                    }
                    type="password"
                    placeholder="Per favore, 'Qwerty123' no."
                    startDecorator={<Key />}
                  />
                  <LinearProgress
                    determinate
                    size="sm"
                    value={Math.min((formData.password.length * 100) / 8, 100)}
                    sx={{
                      bgcolor: "background.level3",
                      color: "hsl(var(--hue) 80% 40%)",
                    }}
                  />
                  <Typography
                    level="body-xs"
                    sx={{
                      alignSelf: "flex-end",
                      color: "hsl(var(--hue) 80% 30%)",
                    }}
                  >
                    {formData.password.length < 3 && "Very weak"}
                    {formData.password.length >= 3 &&
                      formData.password.length < 6 &&
                      "Weak"}
                    {formData.password.length >= 6 &&
                      formData.password.length < 10 &&
                      "Strong"}
                    {formData.password.length >= 10 && "Very strong"}
                  </Typography>
                </Stack>
              </FormControl>
            </Box>
            <Divider />
            <Stack spacing={1} sx={{ padding: 3 }}>
              {!created ? (
                <>
                  <Button
                    type="reset"
                    size="sm"
                    variant="solid"
                    color="neutral"
                  >
                    Annulla
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    variant="solid"
                    loading={isSubmitting}
                  >
                    Salva
                  </Button>
                </>
              ) : (
                <Button
                  color="success"
                  size="sm"
                  variant="solid"
                  onClick={() => navigate("/company", { replace: true })}
                >
                  Fatto, portami nella Dashboard delle Aziende
                </Button>
              )}
            </Stack>
          </Sheet>
        </Box>
      </form>
    </Layout>
  );
}
