import Card from "@mui/joy/Card/Card";
import CardContent from "@mui/joy/CardContent/CardContent";
import Skeleton from "@mui/joy/Skeleton/Skeleton";
import Typography from "@mui/joy/Typography/Typography";
import { PieChart } from "@mui/x-charts/PieChart";

interface DCardServicePieCardProps {
  loading: boolean;
  withCount: number;
  withoutCount: number;
}

export function DCardServicePieCard({
  loading,
  withCount,
  withoutCount,
}: DCardServicePieCardProps) {
  return (
    <Card variant="soft" color="neutral">
      <CardContent orientation="horizontal">
        <Skeleton
          height={90}
          width={90}
          loading={loading}
          variant="rectangular"
        >
          <PieChart
            height={90}
            width={90}
            series={[
              {
                data: [
                  { value: withCount, color: "green" },
                  { value: withoutCount, color: "grey" },
                ],
                innerRadius: 35,
                outerRadius: 25,
                paddingAngle: 10,
                cornerRadius: 3,
                startAngle: 0,
                cx: 40,
                cy: 40,
              },
            ]}
          />
        </Skeleton>
        <CardContent>
          <Typography level="body-md">Servizi dCard attivi</Typography>
          <Skeleton height="100%" loading={loading} variant="rectangular">
            <Typography level="h2">
              {withCount} su {withCount + withoutCount} aziende
            </Typography>
          </Skeleton>
        </CardContent>
      </CardContent>
    </Card>
  );
}
