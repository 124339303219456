import * as React from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { companyApi } from "services/api";
import { CompanyDto } from "services/openapi";
import { activatedDCardServiceFilter } from "components/filters/activatedDCardServiceFilter";
import { DCardOwnershipTableContent } from "./DCardOwnershipTableContent";
import { SkeletonTableContent } from "../SkeletonTable";
import { DCardOwnershipHeaderRow } from "./DCardOwnershipHeaderRow";

export default function OrderTable() {
  const [loaded, setLoaded] = React.useState(false);
  const [companies, setCompanies] = React.useState<CompanyDto[]>([]);

  async function fetchAllCompanies() {
    return await companyApi
      .companyControllerFindAll()
      .then((res) => {
        const allCompanies = res.data;
        const filteredCompanies = activatedDCardServiceFilter(allCompanies);
        setCompanies(filteredCompanies);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoaded(true));
  }

  React.useEffect(() => {
    fetchAllCompanies();
  }, []);

  return (
    <Sheet
      variant="outlined"
      sx={{
        "--TableCell-height": "40px",
        // the number is the amount of the header rows.
        "--TableHeader-height": "calc(1 * var(--TableCell-height))",
        "--Table-firstColumnWidth": "80px",
        "--Table-lastColumnWidth": "144px",
        // background needs to have transparency to show the scrolling shadows
        "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
        "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
        overflow: "auto",
        background: (
          theme
        ) => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
          linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
          radial-gradient(
            farthest-side at 0 50%,
            rgba(0, 0, 0, 0.12),
            rgba(0, 0, 0, 0)
          ),
          radial-gradient(
              farthest-side at 100% 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            )
            0 100%`,
        backgroundSize:
          "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "local, local, scroll, scroll",
        backgroundPosition:
          "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
        backgroundColor: "background.surface",
      }}
    >
      <Table
        aria-labelledby="tableTitle"
        hoverRow
        sx={{
          "& tr > *:first-of-type": {
            position: "sticky",
            left: 0,
            boxShadow: "1px 0 var(--TableCell-borderColor)",
            bgcolor: "background.surface",
          },
          "& tr > *:last-child": {
            position: "sticky",
            right: 0,
            bgcolor: "var(--TableCell-headBackground)",
          },
        }}
      >
        <thead>
          <DCardOwnershipHeaderRow />
        </thead>
        <tbody>
          {loaded ? (
            <DCardOwnershipTableContent
              companies={companies}
              onDCardOwnershipDelete={fetchAllCompanies}
            />
          ) : (
            <SkeletonTableContent nRows={6} nCellPerRow={6} />
          )}
        </tbody>
      </Table>
    </Sheet>
  );
}
