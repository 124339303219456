import Modal from "@mui/joy/Modal/Modal";
import ModalDialog from "@mui/joy/ModalDialog/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle/DialogTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import DialogContent from "@mui/joy/DialogContent/DialogContent";
import DialogActions from "@mui/joy/DialogActions/DialogActions";
import Divider from "@mui/joy/Divider/Divider";
import Button from "@mui/joy/Button/Button";
import { companyApi } from "services/api";

interface CompanyDeleteModalProps {
  companyId: number;
  open: boolean;
  onClose: Function;
  onDelete: Function;
}

export function CompanyDeleteModal({
  open,
  onClose,
  onDelete,
  companyId,
}: CompanyDeleteModalProps) {
  async function handleOnDeleteClick() {
    await companyApi.companyControllerRemove(companyId);
    onDelete();
  }

  return (
    <Modal open={open} onClose={() => onClose()}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Ma ne sei proprio sicuro?
        </DialogTitle>
        <Divider />
        <DialogContent>
          Sei sicuro di voler eliminare l'azienda? Questo comporterà l'immediata
          disattivazione ed eliminazione dei dati di tutti i servizi attivati
          per l'azienda. Sicuro di voler continuare?{" "}
          <b>Dopo non potrai più recuperare nessun dato.</b>
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleOnDeleteClick}>
            Elimina
          </Button>
          <Button variant="plain" color="neutral" onClick={() => onClose()}>
            Annulla
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}
