import Box from "@mui/joy/Box/Box";
import Button from "@mui/joy/Button/Button";
import Card from "@mui/joy/Card/Card";
import CardContent from "@mui/joy/CardContent/CardContent";
import Divider from "@mui/joy/Divider/Divider";
import Sheet from "@mui/joy/Sheet/Sheet";
import Stack from "@mui/joy/Stack/Stack";
import Typography from "@mui/joy/Typography/Typography";
import { AddCard, Check, Close } from "@mui/icons-material";
import React from "react";
import { cardApi, cardOwnershipApi } from "services/api";
import { DCard, DCardOwnership } from "services/openapi";
import Alert from "@mui/joy/Alert/Alert";
import AspectRatio from "@mui/joy/AspectRatio/AspectRatio";
import Chip from "@mui/joy/Chip/Chip";
import Modal from "@mui/joy/Modal/Modal";
import ModalOverflow from "@mui/joy/ModalOverflow/ModalOverflow";
import ModalClose from "@mui/joy/ModalClose/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog/ModalDialog";
import List from "@mui/joy/List/List";
import ListItem from "@mui/joy/ListItem/ListItem";
import IconButton from "@mui/joy/IconButton/IconButton";

interface DCardServiceInfoProps {
  companyID: number;
}

const OwnershipSample: DCardOwnership = {
  id: 0,
  companyId: 0,
  dcards: [],
};

export function DCardServiceInfo({ companyID }: DCardServiceInfoProps) {
  const [, setLoading] = React.useState(true);
  const [ownership, setOwnership] =
    React.useState<DCardOwnership>(OwnershipSample);
  const [openModalAddCard, setOpenModalAddCard] = React.useState(false);

  const fetchOwneship = React.useCallback(async () => {
    setLoading(true);
    const req = await cardOwnershipApi.dCardOwnershipApiControllerFetch(
      companyID
    );
    const ownership = req.data;
    setOwnership(ownership);
    setLoading(false);
    return ownership;
  }, [companyID])

  React.useLayoutEffect(() => {
    fetchOwneship();
  }, [fetchOwneship]);

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: "100%",
        height: "100",
        borderRadius: "sm",
        flexShrink: 1,
        mb: {
          md: 5,
          xs: 12,
        },
      }}
    >
      <Box sx={{ mb: 1, padding: "14px" }}>
        <Typography level="title-md">dCard</Typography>
        <Typography level="body-sm">Abbonamento relativo alle dCard</Typography>
      </Box>
      <Divider />
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        sx={{ mb: 1, padding: "14px" }}
      >
        <Typography level="title-sm">Carte Associate</Typography>
        <Button size="sm" onClick={() => setOpenModalAddCard(true)}>
          Associa dCard
        </Button>
      </Stack>
      <Stack
        sx={{ overflowX: "scroll", "::-webkit-scrollbar": { display: "none" } }}
        marginBottom={1.5}
        spacing={1}
        gap={3}
        flexWrap="wrap"
        useFlexGap
      >
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <Box display="hidden" />
          {ownership.dcards.length === 0 && (
            <Alert color="warning" sx={{ width: "100%" }}>
              Nessuna dCard Associata
            </Alert>
          )}

          {ownership.dcards.map((card) => (
            <CardDCard
              key={"card-dcard-" + card.uuid}
              ownershipID={ownership.id}
              dcard={card}
              onModifyCallback={fetchOwneship}
            />
          ))}
          <Box display="hidden" />
        </Stack>
      </Stack>
      <ModalAddDCards
        ownershipID={ownership.id}
        open={openModalAddCard}
        onClickClose={() => {
          setOpenModalAddCard(false);
          fetchOwneship();
        }}
      />
    </Sheet>
  );
}

interface CardDCardProps {
  ownershipID: number;
  dcard: DCard;
  onModifyCallback: Function;
}

function CardDCard({ ownershipID, dcard, onModifyCallback }: CardDCardProps) {
  return (
    <Card sx={{ width: "85.6mm" }}>
      <CardContent>
        <AspectRatio ratio={1.58}>
          <Stack direction="column" spacing={0.5} alignItems="flex-start">
            <Stack direction="row" spacing={1}>
              <Typography level="body-md">{dcard.title}</Typography>
              {dcard.isActive ? (
                <Chip variant="soft" color="success" startDecorator={<Check />}>
                  Attiva
                </Chip>
              ) : (
                <Chip variant="soft" color="danger" startDecorator={<Close />}>
                  Disattiva
                </Chip>
              )}
            </Stack>
            <Typography level="body-xs">UUID: {dcard.uuid}</Typography>
            <Stack direction="row" spacing={1}>
              <Button
                variant="plain"
                color="neutral"
                size="sm"
                component="a"
                href={"/products/dcard/" + dcard.uuid}
              >
                Apri
              </Button>
              {dcard.isActive ? (
                <Button
                  variant="plain"
                  color="danger"
                  size="sm"
                  onClick={async () => {
                    await cardApi.dCardApiControllerDeactivate(dcard.uuid);
                    onModifyCallback();
                  }}
                >
                  Disattiva
                </Button>
              ) : (
                <Button
                  variant="plain"
                  color="success"
                  size="sm"
                  onClick={async () => {
                    await cardApi.dCardApiControllerActivate(dcard.uuid);
                    onModifyCallback();
                  }}
                >
                  Attiva
                </Button>
              )}
              <Button
                variant="soft"
                color="danger"
                size="sm"
                onClick={async () => {
                  await cardOwnershipApi.dCardOwnershipApiControllerRemoveCard(
                    ownershipID,
                    dcard.uuid
                  );
                  onModifyCallback();
                }}
              >
                Rimuovi
              </Button>
            </Stack>
          </Stack>
        </AspectRatio>
      </CardContent>
    </Card>
  );
}

interface ModalAddDCardsProps {
  ownershipID: number;
  open: boolean;
  onClickClose: Function;
}

function ModalAddDCards({
  ownershipID,
  open,
  onClickClose,
}: ModalAddDCardsProps) {
  const [availableCards, setAvailableCards] = React.useState<DCard[]>([]);

  React.useEffect(() => {
    if (open) {
      cardApi.dCardApiControllerFetchAll().then((req) => {
        const cards = req.data;
        const available = cards.filter((card) => !!!card.ownership);
        console.log(cards);
        setAvailableCards(available);
      });
    }
  }, [open]);

  return (
    <Modal open={open} onClose={() => onClickClose()}>
      <ModalOverflow sx={{ overflow: "auto" }}>
        <ModalDialog aria-labelledby="modal-dialog-overflow">
          <ModalClose />
          <Typography id="modal-dialog-overflow" level="h2">
            dCard Disponibili
          </Typography>
          <List>
            {availableCards.length === 0 && (
              <Alert>Nessuna dCard disponibile</Alert>
            )}
            {availableCards.map((card) => (
              <ListItem key={"to-choose-" + card.uuid}>
                <Card>
                  <IconButton
                    aria-label="add-dcard"
                    variant="soft"
                    color="primary"
                    size="sm"
                    onClick={async () => {
                      await cardOwnershipApi.dCardOwnershipApiControllerAddCard(
                        ownershipID,
                        card.uuid
                      );
                      onClickClose();
                    }}
                    sx={{
                      position: "absolute",
                      zIndex: 9999,
                      top: "0.875rem",
                      right: "0.5rem",
                    }}
                  >
                    <AddCard />
                  </IconButton>
                  <CardContent>
                    <Typography level="body-sm">UUID: {card.uuid}</Typography>
                    <Typography level="title-lg">{card.title}</Typography>
                    <Typography level="body-md">{card.link}</Typography>
                  </CardContent>
                </Card>
              </ListItem>
            ))}
          </List>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
