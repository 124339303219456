import { JSX } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Header from "./Header";
import Sidebar from "./Sidebar";

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

export function Layout(props: Props) {
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <Header />
        <Sidebar />
        {props.children}
      </Box>
    </CssVarsProvider>
  );
}
