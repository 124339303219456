import Cancel from "@mui/icons-material/Cancel";
import CheckRounded from "@mui/icons-material/CheckRounded"
import Chip from "@mui/joy/Chip/Chip"

interface DCardOwnershipStatusChipProps {
    isAssigned: boolean
}

export function DCardOwnershipStatusChip({ isAssigned }: DCardOwnershipStatusChipProps) {
    return isAssigned
        ? <DCardAssignedChip />
        : <DCardUnassignedChip />
}

function DCardAssignedChip() {
    return (
        <Chip
            variant="soft"
            size="sm"
            startDecorator={<CheckRounded />
            }
            color="success"
        >
            Assegnata
        </Chip>
    )
}


function DCardUnassignedChip() {
    return (<Chip
        variant="soft"
        size="sm"
        startDecorator={<Cancel />}
        color="danger"
    >
        Non Assegnata
    </Chip>);
}