import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import DigitalMotusLogo from "assets/DigitalMotusLogo";
import Alert from "@mui/joy/Alert";
import ReportIcon from "@mui/icons-material/Report";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/joy/IconButton";
import { useNavigate } from "react-router";
import { authProvider } from "services/api";
import { useLocation } from "react-router-dom";

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

const SignIn: React.FC = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<SignInFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
    const formElements = event.currentTarget.elements;
    const password = formElements.password.value;

    authProvider
      .signin(password)
      .then(() => {
        setIsError(false);
        navigate(from || "/home");
      })
      .catch((e) => {
        console.log(e);
        setIsError(true);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Collapsed-breakpoint": "769px", // form will stretch when viewport is below `769px`
            "--Cover-width": "40vw", // must be `vw` only
            "--Form-maxWidth": "700px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(4px)",
          backgroundColor: "rgba(255 255 255 / 0.6)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width:
              "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
            maxWidth: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight="lg" startDecorator={<DigitalMotusLogo />}>
              Digital Motus
            </Typography>
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              minWidth: 300,
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: "hidden",
              },
            }}
          >
            <Box>
              <Typography component="h1" fontSize="xl2" fontWeight="lg">
                Accedi all'area amministrativa
              </Typography>
              <Typography level="body-sm" sx={{ my: 1, mb: 3 }}>
                Bentornato
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <FormControl required>
                <input type="hidden" name="redirectTo" value={from} />
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  name="password"
                  autoComplete="current-password"
                />
              </FormControl>
              <Button type="submit" loading={submitting}>
                Accedi
              </Button>
              {isError && (
                <Alert
                  key="Errore"
                  sx={{ alignItems: "flex-start" }}
                  startDecorator={<ReportIcon />}
                  variant="soft"
                  color="danger"
                  endDecorator={
                    <IconButton
                      variant="soft"
                      color="danger"
                      onClick={() => setIsError(false)}
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                  }
                >
                  <div>
                    <div>Errore</div>
                    <Typography level="body-sm" color="danger">
                      Controlla la password o contatta Enrico
                    </Typography>
                  </div>
                </Alert>
              )}
            </form>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Digital Motus {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage:
              "url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)",
          },
        })}
      />
    </CssVarsProvider>
  );
};

export default SignIn;
