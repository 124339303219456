import { DCardDeleteButton, DCardOpenButton, DCardToggleActivationButton } from "components/buttons/dcard";
import { DCardOwnershipOpenButton } from "components/buttons/dcard-ownership/DCardOwnershipOpenButton";
import { DCardActivationStatusChip } from "components/chip/DCardActivationStatusChip";
import { DCardOwnershipStatusChip } from "components/chip/DCardOwnershipStatusChip";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography/Typography";
import { DCard } from "services/openapi";

interface DCardRowProps {
  dcard: DCard;
  onAction?: Function;
}

export function DCardRow({ dcard, onAction }: DCardRowProps) {
  return (
    <tr>
      <td style={{ paddingLeft: "16px", zIndex: 2 }}>
        <Typography level="body-xs">{dcard.uuid}</Typography>
      </td>
      <td>
        <DCardOwnershipStatusChip isAssigned={!!dcard.ownership} />
      </td>
      <td>
        <DCardActivationStatusChip isActive={dcard.isActive} />
      </td>
      <td>
        <Typography level="body-xs">{dcard.title}</Typography>
      </td>
      <td>
        <Typography level="body-xs" noWrap>
          {dcard.link}
        </Typography>
      </td>
      <td style={{ zIndex: 2 }}>
        <Box sx={{ display: "flex", gap: 1, justifyContent: "end" }}>
          <DCardOpenButton dcardUuid={dcard.uuid} />
          {dcard.ownership && <DCardOwnershipOpenButton companyId={dcard.ownership.companyId} />}
          <DCardToggleActivationButton dcard={dcard} onToggle={onAction} />
          <DCardDeleteButton dcardUuid={dcard.uuid} onDelete={onAction} />
        </Box>
      </td>
    </tr>
  );
}
