import { authProvider } from "services/api";
import { createBrowserRouter, redirect } from "react-router-dom";
import { protectedLoader } from "./loaders/protectedLoader";
import { CompanyRoute } from "./routes/CompanyRoute";
import { ProductRoute } from "./routes/ProductRoute";
import { HomeRoute } from "./routes/HomeRoute";
import { SignInRoute } from "./routes/SignInRoute";

export const AppRouter = createBrowserRouter([
  {
    index: true,
    loader: async () => {
      const isAuth = await authProvider.isAuthenticated();
      const destination = isAuth ? "/home" : "sign-in";
      return redirect(destination);
    },
  },
  {
    path: "/",
    loader: protectedLoader,
    children: [
      {
        path: "logout",
        loader: async () => {
          // We signout in a "resource route" that we can hit from a fetcher.Form
          await authProvider.signout();
          return redirect("/");
        },
      },
      HomeRoute,
      CompanyRoute,
      ProductRoute,
    ],
  },
  SignInRoute
]);
